@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}

// CDN
$cdnURL: 'https://cdn.therigy.com';

// Pure grays (lightest to darkest)
$snow: #f7f7f7;
$dove: #e5e5e5;
$silver: #ccc;
$dusty: #9c9c9c;
$gray: #7f7f7f;
$manatee: #636363;
$gargoyle: #474747;
$chimney: #2b2b2b;
$charcoal: #0f0f0f;

// Cool grays (lightest to darkest)
$glass: #f1f6f9;
$diamond: #c0c9ce;
$aluminum: #a7b0b5;
$mirror: #8e979c;
$overcast: #757d82;
$storm: #5b6368;
$dimgray: #666;
$ash: #414a4f;
$shark: #283136;
$cinder: #0f181d;

// Therigy brand colors
$matisse: #166c9d; // Main brand color
$eastern-blue: #269da9; // Light accent
$atoll: #0b6971; // Dark accent
$biscay: #20386c; // Dark Shades

// Brand colors (also used in _bootstrap_overrides.scss)
$brand-primary: $matisse; // $matisse
$brand-success: #3c9b67;
$brand-info: $biscay; // biscay
$brand-warning: #b98b2f;
$brand-danger: #8d1000;

// Additional secondary brand colors
$cadet-blue: #a7b8c6;
$glacier: #75a4c7;

// Layout heights
$footer-height: 50px;
$header-height: 50px;
$navbar-height: 38px;

:export {
    // Pure grays (lightest to darkest)
    snow: #f7f7f7;
    dove: #e5e5e5;
    silver: #ccc;
    dusty: #9c9c9c;
    gray: #7f7f7f;
    manatee: #636363;
    gargoyle: #474747;
    chimney: #2b2b2b;
    charcoal: #0f0f0f;

    // Cool grays (lightest to darkest)
    glass: #f1f6f9;
    diamond: #c0c9ce;
    aluminum: #a7b0b5;
    mirror: #8e979c;
    overcast: #757d82;
    storm: #5b6368;
    dimgray: #666;
    ash: #414a4f;
    shark: #283136;
    cinder: #0f181d;

    // Therigy brand colors
    matisse: #166c9d; // Main brand color
    easternBlue: #269da9; // Light accent
    atoll: #0b6971; // Dark accent
    biscay: #20386c; // Dark Shades

    // Brand colors (also used in _bootstrap_overrides.scss)
    brandPrimary: $matisse; // $matisse
    brandSuccess: #3c9b67;
    brandInfo: $biscay; // biscay
    brandWarning: #b98b2f;
    brandDanger: #8d1000;

    // Additional secondary brand colors
    cadetBlue: #a7b8c6;
    glacier: #75a4c7;

    // Layout heights
    footerHeight: 50px;
    headerHeight: 50px;
    navbarHeight: 38px;
}

:root {
    // Pure grays (lightest to darkest)
    --snow: #f7f7f7;
    --dove: #e5e5e5;
    --silver: #ccc;
    --dusty: #9c9c9c;
    --gray: #7f7f7f;
    --manatee: #636363;
    --gargoyle: #474747;
    --chimney: #2b2b2b;
    --charcoal: #0f0f0f;

    // Cool grays (lightest to darkest)
    --glass: #f1f6f9;
    --diamond: #c0c9ce;
    --aluminum: #a7b0b5;
    --mirror: #8e979c;
    --overcast: #757d82;
    --storm: #5b6368;
    --dimgray: #666;
    --ash: #414a4f;
    --shark: #283136;
    --cinder: #0f181d;

    // Therigy brand colors
    --matisse: #166c9d; // Main brand color
    --easternBlue: #269da9; // Light accent
    --atoll: #0b6971; // Dark accent
    --biscay: #20386c; // Dark Shades

    // Brand colors (also used in _bootstrap_overrides.scss)
    --brandPrimary: #166c9d; // $matisse
    --brandSuccess: #3c9b67;
    --brandInfo: #20386c; // biscay
    --brandWarning: #b98b2f;
    --brandDanger: #8d1000;

    // Additional secondary brand colors
    --ligherBlue: #bddbf4;
    --cadetBlue: #a7b8c6;
    --glacier: #75a4c7;

    // Layout heights
    --footerHeight: 50px;
    --headerHeight: 50px;
    --navbarHeight: 38px;
}
