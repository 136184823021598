.work-queue {
    display: flex;
    flex: 1 1 auto;
    overflow-x: auto;
    flex-direction: column;

    .work-queue-form {
        display: flex;
        flex: 1 1 auto;
    }

    .therigy-table-tooltip {
        border-bottom: 1px dotted #1a9cd9;
    }

    aside {
        background-color: #f1f6f9;
        padding: 20px;
        width: 300px;

        .accordion-wrapper {
            padding-top: 0;
        }
    }

    article {
        background-color: #fff;
        padding: 20px;
        width: 100%;

        .accordion-wrapper {
            padding-top: 0;
        }

        .therigy-table tr {
            font-size: 12px;

            &.assigned-to,
            &.last-updated,
            &.last-updated span {
                white-space: nowrap;
            }

            th {
                font-weight: normal;

                .form-control:not(:placeholder-shown) {
                    color: var(--shark);
                    background-color: #f1f6f9;
                }

                .header-text {
                    font-weight: 700;
                }
            }
        }

        .patient-name-link {
            padding-right: 3px;
        }
    }

    .header-work-queue {
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .filter-section {
        margin-bottom: 20px;

        .filter-section-header {
            color: var(--shark);
            font-size: 18px;
            margin-bottom: 0;
        }

        .filter-section-header-refine-by {
            margin-bottom: 0;
        }

        .bullet {
            vertical-align: 33%;
            font-size: 0.4em;
            color: var(--shark);
        }

        .filter-record {
            font-size: 12px;
            cursor: pointer;
            margin-top: 0;
            padding: 0;
            text-align: left;

            span {
                padding-left: 5px;
                color: var(--matisse);

                &:hover {
                    color: #0d3e5a;
                    text-decoration: underline;
                }
            }

            &.active-filter {
                text-decoration: none;
                font-weight: bold;
            }

            .delete-filter {
                display: none;
            }

            &:hover {
                .delete-filter {
                    display: inline;
                }
            }

            &:focus {
                outline: none;
            }
        }

        .filter {
            display: flex;
            flex-direction: column;
            align-items: start;

            label.filter-header {
                color: var(--shark);
                font-weight: bold;
            }

            label,
            a {
                font-size: 12px;
                margin-bottom: 0;
                color: var(--shark);
            }

            .checkbox,
            .radio {
                margin: 0 0 -3px;
            }

            .checkbox input[type='checkbox'],
            .radio input[type='radio'] {
                margin-top: 2px;
            }
        }

        .form-group {
            label.control-label {
                color: var(--shark);
            }

            legend.control-label {
                color: var(--shark);
                font-weight: bold;
                font-size: 12px;
            }
        }
    }

    .filter-buttons {
        .btn-default.active {
            background-color: var(--matisse);
            color: #fff;
            box-shadow: none;
        }
    }

    .activity-count {
        color: var(--shark);
        font-size: 36px;
        line-height: 1;
    }
}

.patient-contact-information,
.notes-tooltip {
    .tooltip-inner {
        background-color: #f0f0f0;
        box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);
        text-align: left;
        max-width: 100%;
        font-size: 12px;
        color: var(--manatee);
    }
}
